import React, { useState } from 'react'
import { 
    AppBar,
    Container,
    Typography,
    Toolbar,
    Button,
    Box,
    IconButton
    
 } from '@mui/material'
 import { Lock, Menu } from '@mui/icons-material'
import { useValue } from '../context/ContextProvider'
import UserIcons from './user/UserIcons'

import UserLogin from './user/UserLogin'
import SideBar from "./SideBar" 

const NavBar = () => {
  //extract user from reducer
  const {state:{currentUser},dispatch} = useValue()
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ mr: 1 }}>
              <IconButton
                size="large"
                color="inherit"
                onClick={()=> setIsOpen(true)}
              >
                
                <Menu />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              component="h1"
              noWrap
              sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
            >
              Mileage Expense Tracker
            </Typography>
            <Typography
              variant="h6"
              component="h1"
              noWrap
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Mileage Expense Tracker
            </Typography>
            
            {!currentUser ? (
              <Button
                color="inherit"
                startIcon={<Lock />}
                onClick={() => dispatch({ type: 'OPEN_LOGIN' })}
              >
                Login
              </Button>
            ) : (
              <UserIcons />
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar/>
      <SideBar {...{isOpen,setIsOpen}}/>
      </>
  )
}

export default NavBar